.wrapper.bento {
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 15.17%);
  padding: 160px 0 80px 0;
  > .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .item-bento {
      display: flex;
      padding: 24px;
      align-items: flex-end;
      gap: 10px;
      border-radius: 22px;
      background: #fff;
      box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
        0px 2px 4px 0px rgba(17, 24, 28, 0.04);

      h3 {
        color: var(--color-text-primary);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        gap: 12px;
      }
      p {
        color: var(--color-text-secondary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .div1 {
      grid-area: 1 / 9 / 3 / 13;
      display: flex;
      flex-direction: column;

      .img-container {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }

        .socials {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.771);
          // border: solid 1px var(--color-text-secondary);
          display: flex;
          align-items: center;
          padding: 10px;
          gap: 6px;
          border-radius: 12px;
          bottom: 4px;
          right: 0;

          a {
            transition: all 0.3s ease;
            img {
              width: 24px;
              height: 24px;
            }
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .div2 {
      grid-area: 1 / 1 / 2 / 9;
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      overflow: hidden;
      .shape {
        position: absolute;
        top: 0;
      }
      h3 {
        position: relative;
        z-index: 3;
      }
      .img-container {
        img {
          width: 65%;
          position: absolute;
          top: 8px;
        }
      }

      span {
        font-size: 12px;
        display: flex;
        padding: 0px 5px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 6px;
        border: 1px solid #93d3ae;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: #0da14b;
        background: #dbfedd;

        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #0da14b;
        }
      }
    }
    .div3 {
      grid-area: 2 / 6 / 3 / 9;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
      }
    }
    .div4 {
      grid-area: 2 / 1 / 3 / 6;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      .map {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: relative;

        // &:after {
        //   content: "";
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #ffffff 95.17%);
        //   z-index: 5;
        //   pointer-events: none;
        // }

        a {
          display: none;
        }

        .mapboxgl-ctrl-bottom-right {
          display: none;
        }
        .map-container {
          height: 100%;
        }
        canvas {
          width: 100% !important;
          height: 100% !important;
          border-radius: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 860px) {
    > .container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .item-bento {
        padding: 16px;
      }

      .div1 {
        .map {
          height: 260px;
        }
      }

      .div3 {
        display: flex;
        flex-direction: row-reverse;
      }
      .div2 {
        .shape {
          display: none;
        }
      }
    }
    .div4 {
      .map .map-container {
        height: 260px !important;
      }
    }
  }

  @media screen and (max-width: 660px) {
    > .container {
      .div3 {
        display: flex;
        flex-direction: row-reverse;
      }
      .div2 {
        .shape {
          display: none;
        }
        display: flex;
        flex-direction: column-reverse;
        .img-container {
          img {
            position: unset;
            width: 100%;
          }
        }
      }
      .div3 {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
