nav {
  height: 60px;
  position: fixed;
  z-index: 20;
  top: 20px;

  .container {
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: rgba(250, 250, 250, 0.8);
    padding: 12px;
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
      0px 2px 4px 0px rgba(17, 24, 28, 0.04);

    .logo-container,
    .contact-container {
      flex: 1;

      .btn-primary-sm {
        float: right;
      }
    }

    .logo-container {
      img {
        width: 32px;
      }
    }

    .nav-btn {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .nav-items {
      display: flex;

      ul {
        display: flex;
        gap: 24px;

        li {
          list-style: none;
          display: flex;
          align-items: center;

          &.ico {
            gap: 8px;
          }

          a,
          div {
            cursor: pointer;
            text-decoration: none;
            color: var(--color-text-primary);
            font-size: var(--font-size-sm);
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 660px) {
    .container {
      .nav-items {
        display: none;
      }
    }
  }
}
