:root {
  --font-size-xl: 1.625rem; /* 26px ÷ 16px */
  --font-size-lg: 1.125rem; /* 18px ÷ 16px */
  --font-size-md: 0.9375rem; /* 15px ÷ 16px */
  --font-size-sm: 0.875rem; /* 14px ÷ 16px */

  /* Background Colors */
  --color-bg-primary: #ffffff;
  --color-bg-blur: rgba(255, 255, 255, 0.7);

  /* Text Colors */
  --color-text-primary: #262f3f;
  --color-text-secondary: #65719d;
  --color-text-muted: rgba(119, 118, 139, 0.2);

  /* Accent Colors */
  --color-accent: #f5e8fe;

  /* Border/Stroke Colors */
  --color-stroke-primary: #e5e5ea;
  --color-stroke-muted: rgba(0, 0, 0, 0.1);
}

@font-face {
  font-family: "Overused Grotesk";
  src: url("./assets/fonts/OverusedGrotesk-Regular.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overused Grotesk";
  src: url("./assets/fonts/OverusedGrotesk-Bold.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overused Grotesk";
  src: url("./assets/fonts/OverusedGrotesk-Light.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overused Grotesk";
  src: url("./assets/fonts/OverusedGrotesk-Medium.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overused Grotesk";
  src: url("./assets/fonts/OverusedGrotesk-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Overused Grotesk Book";
  src: url("./assets/fonts/OverusedGrotesk-Book.woff2") format("woff2"),
    url("./assets/fonts/OverusedGrotesk-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Overused Grotesk", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    width: 96%;
    max-width: 720px;

    &-min {
      width: 96%;
      max-width: 680px;
    }

    &-lg {
      width: 96%;
      max-width: 960px;
    }
  }
}

.btn-primary {
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--color-text-primary);
  font-size: var(--font-size-md);
  padding: 10px 14px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  color: white;
  height: fit-content;
  border: none;
  box-shadow: 0px 0.75px 0px 0px rgba(255, 255, 255, 0.2) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.4),
    0px 0px 0px 1px #18181b;

  &-sm {
    cursor: pointer;
    padding: 8px 10px;
    font-size: var(--font-size-sm);
    border-radius: 10px;
    text-decoration: none;
    font-weight: 500;
    color: white;
    border: none;
    box-shadow: 0px 0.75px 0px 0px rgba(255, 255, 255, 0.2) inset,
      0px 1px 2px 0px rgba(0, 0, 0, 0.4), 0px 0px 0px 1px #18181b;
    transition: all 0.3s ease;
    background-color: var(--color-text-primary);
    &:hover {
      background-color: #1a1a1a;
    }
  }

  &:hover {
    // transform: scale(1.05);
    background-color: #1a1a1a;
  }
}

.btn-secondary {
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;
  font-size: var(--font-size-md);
  padding: 10px 14px;
  height: fit-content;

  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  color: var(--color-text-primary);
  border: none;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);

  &.disabled {
    cursor: not-allowed;
    background-color: var(--color-stroke-primary);
    color: var(--color-text-secondary);

    pointer-events: none;
  }

  &:hover {
    background-color: #f0f0f0a4;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 20;
}
