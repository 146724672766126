.works {
  h2 {
    text-align: center;

    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 44.8px */
    margin-bottom: 60px;
  }
}

.my-masonry-grid {
  display: flex;
  margin-left: -16px; // Espacement entre les colonnes
  width: auto;

  & > .my-masonry-grid_column {
    padding-left: 16px; // Espacement entre les colonnes
    background-clip: padding-box;
  }
}

.project-item {
  margin-bottom: 16px;
  background-color: #fafafa;
  box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
    0px 2px 4px 0px rgba(17, 24, 28, 0.04);
  border-radius: 10px;
  overflow: hidden;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    width: 100%;
  }

  .img-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    img,
    video {
      margin-top: -2px;
      width: 101%;
      border-radius: 10px;
      border: solid 1px var(--color-stroke-primary);
      object-fit: fill;
      image-rendering: pixelated; /* Préserve le rendu pixelisé */
      image-rendering: crisp-edges; /* Pour d'autres navigateurs */
    }
  }

  p {
    font-size: 14px;
    color: var(--color-text-secondary);
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: #0d99ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      padding-top: 3px;
    }
  }
}
