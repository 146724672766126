.cards {
  padding: 220px 0;

  .container {
    position: relative;
  }

  h2 {
    text-align: center;
    color: #262f3f;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 44.8px */
  }
  .container {
    // width: 100%;
    height: fit-content;

    // background: url(../../assets/img/bg.svg) no-repeat center center/cover;

    .drag {
      position: absolute;
      right: 0%;
      top: 8%;
    }

    .cards-container {
      width: 100%;
      min-height: 720px;
      position: relative;
      padding-top: 40px;

      .map-card {
        background-color: #2fc378 !important;

        h3 {
          color: white !important;
          margin-bottom: 20px;
        }
      }

      .card {
        will-change: transform;
        position: absolute;
        border-radius: 18px;
        display: flex;
        width: 330px;
        height: 450px;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 11px;
        flex-shrink: 0;
        border: 1px solid rgba(230, 230, 230, 0.8);
        background: rgba(250, 249, 247, 0.8);
        box-shadow: 0px 1px 45px 0px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(3px);
        cursor: grab;

        &:first-child {
          z-index: 10;
          left: 35%;
          top: 20%;
        }

        &:nth-child(2) {
          z-index: 9;
          left: 40%;
          top: 16%;
        }

        &:nth-child(3) {
          z-index: 8;
          left: 45%;
          top: 12%;
        }

        &:nth-child(4) {
          z-index: 7;
          left: 50%;
          top: 8%;
        }

        &:nth-child(5) {
          z-index: 6;
          left: 55%;
          top: 7%;
        }

        img {
          width: 100%;
          pointer-events: none;
          height: 240px;
          object-fit: cover;
          border-radius: 8px;
          border: solid 1px rgba(0, 0, 0, 0.1);
        }

        .card-content {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
          height: 100%;
          .text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h3 {
              font-size: 18px;
              color: var(--color-text-primary);
              font-weight: 500;
            }
            p {
              font-size: 16px;
              color: var(--color-text-secondary);
              font-weight: 400;
            }
          }
          &.big {
            h3 {
              font-size: 70px;
              line-height: 64px;
            }
          }

          .tools {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
            img {
              border: none !important;
              width: 62px;
              height: 62px;
            }
          }

          .social {
            display: flex;
            gap: 8px;

            a {
              width: 32px;
              height: 32px;
              border-radius: 6px;
              border: 0.5px solid rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0.733px 1.465px 0px rgba(0, 0, 0, 0.05);
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                background-color: #f0f0f0a4;
              }
            }
          }

          .btn-container {
            display: flex;
            justify-content: flex-end;

            button {
              width: fit-content;
            }
          }
        }

        .map {
          height: 100%;
          .mapboxgl-ctrl-bottom-right {
            display: none;
          }
          .map-container {
            height: 100%;
          }
          canvas {
            width: 100% !important;
            height: 100% !important;
            border-radius: 12px;
          }
        }
      }
    }
  }

  .about-content {
    display: flex;
    flex-direction: column;
    gap: 22px;
    p {
      color: #262f3f;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 38.4px */

      span {
        background-color: rgba(13, 154, 255, 0.2);
        border: solid 1px #0d99ff;
        padding: 0 2px;
        position: relative;
      }
    }
  }

  @media screen and (max-width: 660px) {
    padding: 220px 0 120px 0;
    .container {
      min-height: unset;
      background: unset;
      width: 100%;
      overflow: hidden;

      .about-content {
        width: 96%;
        margin: auto;
        p {
          font-size: 22px;
        }
      }

      .drag {
        display: none;
      }
      .cards-container {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        background: unset;
        min-height: unset;
        width: 100%;
        padding-bottom: 80px;
        .card {
          position: unset;
          // transform: scale(0.8) !important;
        }
      }
    }
  }
}
