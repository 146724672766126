footer {
  // background-color: #fafafa;
  padding: 100px 0 20px 0;
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
      0px 2px 4px 0px rgba(17, 24, 28, 0.04);
    padding: 24px;
  }
}
