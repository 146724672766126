// Home
.wrapper.hero {
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 480px;
    gap: 22px;

    h1 {
      font-size: var(--font-size-xl);
      position: relative;
      z-index: 10;
      font-weight: 500;
      max-width: 660px;
      line-height: 38px;

      span {
        padding: 0 2px;
        position: relative;
        margin-left: 4px;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: rgba(13, 154, 255, 0.2);
          border: solid 1px #0d99ff;
          z-index: -1;
          animation: widthAnimation 0.8s ease forwards;
        }

        @keyframes widthAnimation {
          from {
            width: 0%;
          }
          to {
            width: 100%;
          }
        }

        .arrow {
          position: absolute;
          bottom: -30px;
          right: 0px;
          animation: followBefore 0.8s ease forwards;
        }

        @keyframes followBefore {
          from {
            right: 90%;
          }
          to {
            right: -30px;
          }
        }
      }
    }

    .btn-container {
      display: flex;
      gap: 8px;
    }

    .white-book {
      margin-top: 40px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
      padding: 10px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-text-secondary);
      font-weight: 500;
      border-radius: 10px;
      justify-self: flex-end;
      text-decoration: none;
      font-size: 14px;
      transition: all 0.3s ease;

      &:hover {
        background-color: #f0f0f0a4;
      }
    }
  }
}

.cs {
  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .project-card {
      transition: all 0.3s ease;
      text-decoration: none;
      background-color: #fafafa;
      border-radius: 12px;
      box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
        0px 2px 4px 0px rgba(17, 24, 28, 0.04);
      overflow: hidden;

      .content {
        padding: 32px;

        .title {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        h2 {
          color: #262f3f;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        p {
          color: #777;

          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }

      .img {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 120px;

        padding-bottom: -80px;
        max-height: 180px;
        img {
          width: 100%;
          border-radius: 12px;
          border: 5px solid #fff;
          box-shadow: 0px 0px 27.7px 1px rgba(17, 24, 28, 0.08),
            0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04);
        }
        &.newgo {
          margin-right: 120px;
          margin-top: 140px;
          width: auto;
          img {
            transition: all 0.3s ease;
            height: 380px;
            width: auto;
            &:first-child {
              transform: rotate(-6deg);
              z-index: 1;
            }
            &:last-child {
              transform: rotate(6deg);
            }
          }
        }

        &.halto {
          margin-right: 100px;

          img {
            transition: all 0.3s ease;
            height: 380px;
            width: auto;
            &:first-child {
              transform: rotate(-6deg);
            }
            &:last-child {
              transform: rotate(6deg);

              z-index: 1;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0px 0px 25.8px 6px rgba(17, 24, 28, 0.08),
          0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04);
        .newgo {
          img {
            &:first-child {
              transform: rotate(-5deg) scale(1.1);
              z-index: 1;
            }
            &:last-child {
              transform: rotate(5deg) scale(1.1);
            }
          }
        }
        .halto {
          img {
            &:first-child {
              transform: rotate(-5deg) scale(1.1);
              z-index: 1;
            }
            &:last-child {
              transform: rotate(5deg) scale(1.1);
            }
          }
        }
      }
    }
  }
}

.wrapper.projects {
  .container {
    h2 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      color: var(--color-text-primary);
      margin-bottom: 20px;
    }

    img {
      width: 100%;

      object-fit: cover;
      border-radius: 16px;
    }
    .grid-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.wrapper.works {
  padding-bottom: 120px;
  .container {
    h2 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      color: var(--color-text-primary);
      margin-bottom: 20px;
    }

    .grid-3 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
      .col {
        display: flex;
        flex-direction: column;
        gap: 16px;
        img,
        video {
          cursor: pointer;
          width: 100%;
          border-radius: 10px;
          border: solid 1px rgba(0, 0, 0, 0.2);
        }
        p {
          font-size: var(--font-size-sm);
          color: var(--color-text-secondary);
        }

        .img-container {
          position: relative;
          a {
            position: absolute;
            text-decoration: none;
            bottom: 16px;
            right: 16px;
            button {
              display: flex;
              align-items: center;
              padding: 8px 12px;
              background: rgba(0, 0, 0, 0.8);

              svg {
                width: 16px;
                height: 16px;
                transform: rotate(-45deg);
              }

              &:hover {
                background: rgba(0, 0, 0, 1);
              }
            }
          }

          &:hover {
            a {
              display: block;
            }
          }
        }
      }
    }
  }
}

.lightbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 25;

  .btns {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    button {
      cursor: pointer;
      transition: all 0.3s ease;
      background-color: rgba(0, 0, 0, 0.6);
      font-size: var(--font-size-md);
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      text-decoration: none;
      font-weight: 500;
      color: white;
      border: solid 1px rgba(255, 255, 255, 0.1);

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        border: solid 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
}

#about {
  .go-arrow {
    position: absolute;
    bottom: -140px;
    right: 50px;
  }

  @media screen and (max-width: 860px) {
    .go-arrow {
      display: none;
    }
  }
}

.lightbox img,
video {
  max-width: 100%;
  height: auto;
  border-radius: 14px;
  max-height: 80vh;
}

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay avec opacité */
  z-index: 15; /* Doit être derrière la lightbox */
}

// Project

.wrapper.project-page {
  padding-top: 120px;
  // background-color: #fafafa;

  .logo {
    margin-bottom: 16px;
    img {
      width: 48px;
      height: 48px;
    }
  }

  .title {
    .title-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;

      .btn-primary-sm {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .txt {
        h1 {
          font-size: 28px;
          color: var(--color-text-primary);
          margin-bottom: 8px;
          font-weight: 500;
        }
        h3 {
          color: var(--color-text-secondary);
          font-weight: 500;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--stroke, #e5e5ea);
    margin: 22px 0;
  }

  .infos-project {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    gap: 24px;
    border-radius: 12px;
    background: var(--White-BG, #fff);
    justify-content: space-between;
    box-shadow: 0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08),
      0px 2px 4px 0px rgba(17, 24, 28, 0.04);

    .info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      h6 {
        font-size: 12px;
        color: var(--color-text-secondary);
        font-weight: 500;
      }
      p {
        font-size: 14px;
        color: var(--color-text-primary);
      }
    }
  }

  .content-project {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .part {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        img {
          width: 100%;
        }
      }

      img {
        border-radius: 16px;
        border: solid 1px var(--color-stroke-muted);
      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text-primary);
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: var(--color-text-secondary);
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .wrapper.hero {
    .container {
      .left-shape {
        left: -35% !important;
      }
      .right-shape {
        right: -20% !important;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .wrapper.hero {
    .container {
      .left-shape {
        display: none;
      }
      .right-shape {
        display: none;
      }
      h1 {
        font-size: 22px;
        line-height: 28px;

        span {
          &:before {
            display: none;
          }
          background-color: rgba(13, 154, 255, 0.2);
          border: solid 1px #0d99ff;
        }
      }

      .white-book {
        width: 80%;
      }
    }
  }

  // .wrapper.project-page {
  //   .infos-project {
  //     flex-direction: wr;
  //     gap: 16px;
  //   }
  // }
}

@media screen and (max-width: 512px) {
  .cs .container .project-card {
    .img {
      margin: 0 !important;
      img {
        transform: rotate(0) !important;
        width: 90% !important;
        height: auto !important;
        &:last-child {
          display: none;
        }
      }
    }
  }
}
